<template>
  <BaseModal v-if="modalStore.activeModal === 'improve'">
    <div class="max-w-500">
      <span class="subtitle">Classify images to improve Frost Vision results for your sensors</span>
      <div class="selected-image mt-2 mb-2">
        <img :src="modalStore.modalProps.imageUrl" alt="Improve this image" />
      </div>
      <form @submit="handleSubmit">
        <div class="form-group">
          <span class="group-label">Road Conditions</span>
          <span class="subtitle">Select all that you see in the image above</span>
          <div class="checkbox-group">
            <label for="clear_pavement">
              <input type="checkbox" id="clear_pavement" name="clear_pavement" value="clear_pavement" />
              Clear/Dry</label>
            <label for="wet">
              <input type="checkbox" id="wet" name="wet" value="wet" />
              Wet</label>
            <label for="partial_snow_on_road">
              <input type="checkbox" id="partial_snow_on_road" name="partial_snow_on_road"
                value="partial_snow_on_road" />
              Partial Snow</label>
            <label for="snow_on_road">
              <input type="checkbox" id="snow_on_road" name="snow_on_road" value="snow_on_road" />
              Snow</label>
            <label for="icy">
              <input type="checkbox" id="icy" name="icy" value="icy" />
              Icy</label>
            <label for="slushy">
              <input type="checkbox" id="slushy" name="slushy" value="slushy" />
              Slushy</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Was the surface plowed?</span>
          <div class="radio-group">
            <input type="radio" id="plowed-yes" name="plowed" value="yes" />
            <label for="plowed-yes">
              Yes</label>
            <input type="radio" id="plowed-no" name="plowed" value="no" />
            <label for="plowed-no">
              No</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Weather Conditions</span>
          <span class="subtitle">Select all that you see in the image above</span>
          <div class="checkbox-group">
            <label for="clear">
              <input type="checkbox" id="clear" name="clear" value="clear" />
              Clear</label>
            <label for="cloudy">
              <input type="checkbox" id="cloudy" name="cloudy" value="cloudy" />
              Cloudy</label>
            <label for="rain">
              <input type="checkbox" id="rain" name="rain" value="rain" />
              Raining</label>
            <label for="snowing">
              <input type="checkbox" id="snowing" name="snowing" value="snowing" />
              Snowing</label>
            <label for="mixed">
              <input type="checkbox" id="mixed" name="mixed" value="mixed" />
              Mixed Rain/Snow</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Is it night?</span>
          <div class="radio-group">
            <label for="night-yes">
              <input type="radio" id="night-yes" name="night" value="yes" />
              Yes</label>
            <label for="night-no">
              <input type="radio" id="night-no" name="night" value="no" />
              No</label>
          </div>
        </div>
        <div class="form-group">
          <span class="group-label">Is the lense iced-over?</span>
          <div class="radio-group">
            <label for="iced-yes">
              <input type="radio" id="iced-yes" name="iced" value="yes" />
              Yes</label>
            <label for="iced-no">
              <input type="radio" id="iced-no" name="iced" value="no" />
              No</label>
          </div>
        </div>
        <div class="flex">
          <button class="btn btn-primary text-uppercase ml-auto"><i class="fa fa-save mr-1"></i>Confirm</button>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import { computed } from 'vue';
import { useModalStore } from '../../js/store';
import BaseModal from './baseModal.vue';
import api from '../../js/api';

const API = new api();

const modalStore = useModalStore();

// strip off URL parameters
const imageUrl = computed(() => modalStore.modalProps?.imageUrl?.split('?')[0]);

async function handleSubmit(e) {
  e.preventDefault();
  const formData = new FormData(e.target);
  const data = {
    clear_pavement: formData.getAll('clear_pavement').length > 0 ? 1 : 0,
    wet_pavement: formData.getAll('wet').length > 0 ? 1 : 0,
    partial_snow_on_road: formData.getAll('partial_snow_on_road').length > 0 ? 1 : 0,
    snow_on_road: formData.getAll('snow_on_road').length > 0 ? 1 : 0,
    icy: formData.getAll('icy').length > 0 ? 1 : 0,
    night: formData.get('night') === 'yes' ? 1 : 0,
    iced_lens: formData.get('iced') === 'yes' ? 1 : 0,
    raining: formData.getAll('rain').length > 0 ? 1 : 0,
    snowing: formData.getAll('snowing').length > 0 ? 1 : 0,
    sunny: formData.getAll('clear').length > 0 ? 1 : 0,
    cloudy: formData.getAll('cloudy').length > 0 ? 1 : 0,
    labelVersion: 2,
    imageUrl: imageUrl.value,
  };

  const response = await API.postFrostVisionFeedback(data);
  if (response === 'Feedback published successfully') {
    modalStore.closeModal();
  } else {
    console.error('Error submitting feedback');
  }
}

</script>