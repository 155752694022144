<template>
    <div>
        <div class="container" style="max-width: 600px; margin: auto; padding: 2rem;">
            <div class="flex-row">
                <div class="col-12">
                    <h1>Snow Depth Sensor Mobile App</h1>
                    <p>A mobile app is available to setup and synchronize Snow Depth Sensors. These devices use
                        bluetooth and while they can sync with RWIS devices and Frost Vision Cameras, a smartphone is
                        necessary to set them up.
                    </p>
                    <p>Download the mobile app for your device:</p>
                    <div class="flex-row flex-wrap" style="gap:10px;">
                        <div class="flex">
                            <a :href="iOSLink" target="_blank">
                                <img height="80px" :src="appStoreBadge" alt="Download on the App Store" />
                            </a>
                        </div>
                        <div class="flex">
                            <a :href="androidLink" target="_blank">
                                <img height="80px" :src="playStoreBadge" alt="Get it on Google Play" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import playStoreBadge from '../../images/google-play-badge.png';
import appStoreBadge from '../../images/app-store-badge.svg';

const iOSLink = "https://apps.apple.com/us/app/frost-solutions/id6677057392";
const androidLink = "https://play.google.com/store/apps/details?id=io.frostsolutions.mobileapp";

const openAppStore = () => {
    window.location.href = iOSLink;
}
const openPlayStore = () => {
    window.location.href = androidLink;
}
const platform = navigator.userAgentData.platform

// Detect if they are on an iOS device
const isIOS = () => {
    if (platform === 'iOS') {
        return true;
    }
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
// Detect if they are on an Android device
const isAndroid = () => {
    if (platform === 'Android') {
        return true;
    }
    return navigator.userAgent.toLowerCase().indexOf("android") > -1;
}
onMounted(() => {
    if (isIOS()) {
        openAppStore();
    } else if (isAndroid()) {
        openPlayStore();
    }
});
</script>