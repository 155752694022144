<template>
  <div class="frost-vision-logo-wrapper bg-charcoal transparent-80"
    :style="{ minWidth: percentage > 0 ? minWidth : '' }">
    <ejs-tooltip ref="tooltip" :content="tooltipContent" opensOn="Auto" width="150" :beforeRender="onBeforeRender"
      cssClass="frost-vision-tooltip" closeDelay="750" :afterOpen="afterOpen" :afterClose="afterClose">
      <template v-if="size === 'sm'">
        <frost-vision-logo-sm :fill="fill" :percentage="percentage" />
      </template>
      <template v-else>
        <frost-vision-logo :fill="fill" :percentage="percentage" />
      </template>
    </ejs-tooltip>
    <span class="text-white">
      {{ weatherItem.label }}{{ weatherItem.value > 0 && surfaceItem.value > 0 ? ', ' : '' }} {{ surfaceItem.label }}
    </span>
    <a v-if="SHOW_FEEDBACK && percentage > 0 && size !== 'sm'" @click="openModal"
      class="text-secondary text-xs improve-link" href="#improve">Improve
      these
      results</a>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { round, reduce, maxBy } from 'lodash'

import FrostVisionLogo from './frostVisionLogo.vue'
import FrostVisionLogoSm from './frostVisionLogoSm.vue'

import colors from '../js/colors'
import { surfaceMap, weatherMap } from '../js/constants'

import { useModalStore } from '../js/store'

const SHOW_FEEDBACK = process.env.VUE_APP_SHOW_FEEDBACK === 'true'

const modalStore = useModalStore()

const frostVisionMap = {
  Dry: {
    color: colors['dry-surface'],
    label: 'Dry Surface',
  },
  'Wet': {
    color: colors['wet-surface'],
    label: 'Wet',
  },
  Snowing: {
    color: colors['snowing'],
    label: 'Snowing',
  },
  'Partial Snow': {
    color: colors['snow-surface'],
    label: 'Snow',
  },
  'Snow': {
    color: colors['snow-surface'],
    label: 'Snow',
  },
  Icy: {
    color: colors['icy-surface'],
    label: 'Icy Surface',
  },
  Clear: {
    color: colors['clear'],
    label: 'Clear',
  },
  Fallback: {
    color: '#fff',
    label: 'Fallback',
  },
}

const minWidth = computed(() => props.size === 'lg' ? '130px' : '')

const props = defineProps({
  computerVision: {
    type: Object,
    default: () => ({}),
  },
  imageUrl: String,
  size: String,
})
function reduceSurfaceOrWeather(computerVision, map) {
  return reduce(map, (acc, label, key) => {
    if (computerVision[key] > acc.value) {
      acc = { label: frostVisionMap[label]?.label || label, value: computerVision[key] }
    }
    return acc
  }, { value: 0 })
}

function openModal(e) {
  e.preventDefault()
  modalStore.openModal('improve', { title: 'Improve Frost Vision', imageUrl: props.imageUrl })
}

function afterOpen(args) {
  const anchorTag = args.element.querySelector('a')
  const imageUrl = anchorTag.getAttribute('data-image-url')
  anchorTag.addEventListener('click', (e) => openModal(e, imageUrl))
}
function afterClose(args) {
  // Remove event listener
  const anchorTag = args.element.querySelector('a')
  anchorTag.removeEventListener('click', openModal)
}
// Grab highest surface value and highest weather value
const surfaceItem = computed(() => reduceSurfaceOrWeather(props.computerVision, surfaceMap))
const weatherItem = computed(() => reduceSurfaceOrWeather(props.computerVision, weatherMap))
const highestConfidence = computed(() => maxBy([surfaceItem.value, weatherItem.value], 'value'))
const fill = computed(() => frostVisionMap[highestConfidence.value.label]?.color || frostVisionMap.Fallback.color)
const percentage = computed(() => round(highestConfidence.value.value))

const tooltipContent = computed(() => percentage.value > 0 ? '' : `This image does not have an adverse weather or surface condition.
  ${SHOW_FEEDBACK ? `<br>
  <a class="text-secondary" href="#improve" data-image-url="${props.imageUrl}">Improve these results?</a>` : ''}`)

function onBeforeRender(args) {
  if (percentage.value > 0) {
    args.cancel = true
  }
}

</script>