<template>
    <div class="dm-page-container dm-flex-column dm-height-100">
        <dm-table ref="grdGroups" :table="{
            grdTableItems: grdGroupsItems,
            grdTableRowOnClick: grdGroupsRowOnClick,
            grdTableActionOnClick: grdGroupsActionOnClick,
            grdTableSortBy: grdGroupsSortBy,
            grdTableFields: grdGroupsFields,
        }" :allowPaging="true" />
    </div>

</template>

<script>
import Vue from 'vue'

export default {
    name: 'groups-page',
    data: function () {
        return {
            searchParams: {},
            grdGroupsFields: [],
            grdGroupsItems: [],
            grdGroupsSortBy: {
                columns: [
                    { field: 'Name', direction: 'Ascending' },
                ]
            },
            grdGroupsClass: 'dm-bg-color-black',
            searchGroupType: '',
            groupTypeOptions: [],
            searchText: '',
            grdGroupsActions: [
                {
                    text: 'Edit'
                },
                // {
                //     text: 'Delete'
                // }
            ],
            selectedRow: null,
            includeInactive: false,

            // TODO: Remove if unused
            // searchCounty: '',
            // countyOptions: [],
            // searchOrganization: '',
            // organizationOptions: [],
        }
    },
    methods: {
        getGroups(params) {
            DM.http({
                method: 'GET',
                url: '/groups',
                params: params
            }).then(response => {
                this.groups = response;
                this.grdGroupsItems = response;
            });
        },
        grdGroupsRowOnClick(args) {
            this.selectedRow = args;
        },
        addGroupOnClick() {
            eventBus.$emit('showAppModal', this, 'groupsEditModal', {
                mode: "new",
                group: {},
                groupTypeOptions: this.groupTypeOptions,
                callback: () => this.getGroups(this.searchParams)
            });
        },
        grdGroupsActionOnClick(args) {
            if (args.item.text === 'Edit') {
                DM.http({
                    method: 'GET',
                    url: `/groups/${this.selectedRow.rowData.ID}`,
                }).then(response => {
                    eventBus.$emit('showAppModal', this, 'groupsEditModal', {
                        mode: "edit",
                        group: response,
                        groupTypeOptions: this.groupTypeOptions,
                        callback: () => this.getGroups(this.searchParams)
                    });
                });
            }

        },
        deleteGroup(groupId) {
            DM.http({
                method: 'POST',
                url: '/Group/Delete',
                params: {
                    id: groupId,
                }
            }).then(response => {
                this.getGroups();
            });
        },
        searchGroupsOnClick() {
            this.getGroups();
        },
        defineColumns() {
            let self = this;

            if (window.innerWidth < 666) {
                this.grdGroupsFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                                        :items="grdGroupsActions"
                                                        :select="select"
                                                        iconCss="e-icons e-ellipsis-vert"
                                                        cssClass= "e-caret-hide"
                                                    />`,
                                    data: function () {
                                        return {
                                            grdGroupsActions: self.grdGroupsActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdGroupsActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: "Name",
                        label: "Name"
                    },
                    {
                        key: "IsActive",
                        label: "Inactive",
                        formatter: (field, data) => {
                            return data.IsActive ? '' : 'Inactive';
                        },
                        width: '120px',
                    },
                ];
            } else {
                this.grdGroupsFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                            :items="grdGroupsActions"
                                            :select="select"
                                            iconCss="e-icons e-ellipsis-vert"
                                            cssClass= "e-caret-hide"
                                        />`,
                                    data: function () {
                                        return {
                                            grdGroupsActions: self.grdGroupsActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdGroupsActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: "Name",
                        label: "Name"
                    },
                    {
                        key: "City",
                        label: "City"
                    },
                    {
                        key: "State",
                        label: "State"
                    },
                    {
                        key: "Country",
                        label: "Country"
                    },
                    {
                        key: "IsActive",
                        label: "Inactive",
                        formatter: (field, data) => {
                            return data.IsActive ? '' : 'Inactive';
                        },
                        width: '120px',
                    },
                ];
            }
        },
    },
    created() {
        this.getGroups({ includeInactive: false });

        this.defineColumns();

        eventBus.$on('openAddGroup', this.addGroupOnClick);
        eventBus.$on('searchItems', (params) => {
            this.searchParams = params;
            this.getGroups(params);
        });
    },
    mounted() {
        let defineColumns;
        window.onresize = () => {
            clearTimeout(defineColumns);
            defineColumns = setTimeout(this.defineColumns, 100);
        };
    },
    beforeDestroy() {
        eventBus.$off('openAddGroup', this.addGroupOnClick);
        eventBus.$off('searchItems', (params) => {
            this.searchParams = params;
            this.getGroups(params);
        });
    }
}
</script>