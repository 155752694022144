<template>
    <ejs-dialog :id="modalName" :target="sfTarget" :ref="modalName" :header="lblTitle" isModal="true"
        :showCloseIcon="showCloseIcon" :width="'600px'" visible="false" cssClass="scrollable">
        <div class="dm-flex-column dm-overflow-y-auto dm-padding-md">
            <div class="dm-flex-row dm-margin-bottom-md">
                <div class="dm-margin-right-md dm-flex-1">
                    <ejs-textbox v-model="firstName" floatLabelType="Auto" placeholder="First Name"> </ejs-textbox>
                </div>

                <div class="dm-flex-1">
                    <ejs-textbox v-model="lastName" floatLabelType="Auto" placeholder="Last Name"> </ejs-textbox>
                </div>
            </div>

            <div class="dm-flex-row">
                <div class="dm-flex-1">
                    <ejs-textbox v-model="jobTitle" floatLabelType="Auto" placeholder="Job Title"> </ejs-textbox>
                </div>

                <div v-if="mode !== 'profile'" class="dm-flex-1 dm-margin-left-md">
                    <ejs-textbox v-model="password" floatLabelType="Auto" placeholder="Password" autocomplete="off">
                    </ejs-textbox>
                </div>
            </div>

            <div class="dm-flex-row dm-margin-top-md">
                <div class="dm-flex-1">
                    <ejs-textbox v-model="emailAddress" floatLabelType="Auto" placeholder="Email" autocomplete="off">
                    </ejs-textbox>
                </div>
            </div>

            <div class="dm-flex-row dm-margin-top-md dm-align-items-center">
                <div class="dm-flex-1">
                    <ejs-textbox v-model="phone" floatLabelType="Auto" placeholder="Phone"> </ejs-textbox>
                </div>

                <div v-if="isUserSuperAdmin && mode !== 'profile'" class="dm-margin-left-md dm-flex-1">
                    <ejs-checkbox v-model="isSuperAdmin" label="Super Admin"> </ejs-checkbox>
                </div>
            </div>
            <div v-if="isUserSuperAdmin && modalData.mode == 'edit'"
                class="dm-flex-row dm-margin-top-md dm-margin-bottom-md">
                <form @submit="copyPermissions" class="flex-column w-full">
                    <label for="user-permission-replace">Copy Permissions From:</label>
                    <div class="flex-row">
                        <v-select id="user-permission-replace" class="drop-down-filter" :options="userOptions"
                            :selected="userIdToCopy" placeholder="Select User" @input="selectUserToCopy"></v-select>
                        <button class="btn btn-primary ml-2" :disabled="userIdToCopy === null"
                            type="submit">Copy</button>
                    </div>
                </form>
            </div>

            <div v-if="mode !== 'profile'" class="dm-flex-row" style="height: 200px; border-bottom: 1px solid #e0e0e0;">
                <dm-table ref="grdGroup" :table="{
                    grdTableItems: grdGroupItems,
                    grdTableRowOnClick: grdGroupRowOnClick,
                    grdTableActionOnClick: grdGroupActionOnClick,
                    grdTableSortBy: grdGroupSortBy,
                    grdTableFields: grdGroupFields,
                    class: grdGroupClass
                }" />
            </div>

            <div v-show="mode !== 'profile' && userHasBeenSelectedAsAUser" class="dm-flex-row dm-margin-top-md"
                style="height: 200px;">
                <ejs-grid id="userEditModalGrdLocationsList" ref="grdLocationsList" :dataSource="grdLocationsItems"
                    :recordClick="grdLocationsRowOnClick" :rowSelected="grdLocationsRowOnClick"
                    :rowDeselected="grdLocationsRowOnClick" :commandClick="grdLocationsActionOnClick"
                    :allowSorting="true" :sortSettings="grdLocationsSortBy" class="dm-flex-1" :allowTextWrap="true"
                    :textWrapSettings="{ wrapMode: 'Header' }" :selectionSettings="selectionOptions"
                    :allowGrouping="true" :groupSettings="grdLocationsGroupOptions" @rowDataBound="rowDataBound"
                    height="100%" :loadingIndicator="false">
                    <e-columns>
                        <e-column v-for="(field, index) in grdLocationsFields" :key="index" :field="field.key"
                            :headerText="field.label" :template="field.template" :type="field.type"
                            :format="formatColumn(field)" :formatter="field.formatter"
                            :allowSorting="field.allowSorting === undefined ? true : field.allowSorting"
                            :commands="field.commands" :width="field.width" :customAttributes="field.classes"
                            :disableHtmlEncode="false" :textAlign="field.textAlign">
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>

        <div class="dm-flex-row dm-justify-content-space-between dm-margin-md">
            <div>
                <ejs-checkbox v-if="mode !== 'profile' && signedInUserIsNotSelectedUser && mode !== 'new'"
                    v-model="isActive" label="Active">
                </ejs-checkbox>
            </div>

            <div>
                <ejs-button v-if="mode === 'profile'" @click.native="btnOpenChangePasswordOnClick" is-primary="true"
                    cssClass="dm-margin-right-md">
                    <i class="fa fa-lock"></i> <span class="dm-margin-left-sm">Change Password</span>
                </ejs-button>

                <ejs-button @click.native="btnSaveOnClick" is-primary="true">
                    Save
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
import Vue from 'vue'
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import { useBaseStore } from '../../js/store';
import app from '../../js/app';
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
import { min } from 'lodash';
import API from '../../js/api';

const api = new API();

export default {
    name: 'users-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'usersEditModal' + DM.getRandomGuid(),
            lblTitle: 'Add User',
            emailAddress: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '',
            jobTitle: '',
            mode: '',
            grdGroupFields: [],
            grdGroupItems: [],
            grdGroupSortBy: {
                columns: [
                    { field: 'Name', direction: 'Ascending' },
                ]
            },
            grdGroupClass: '',
            grdLocationsFields: [],
            grdLocationsItems: [],
            grdLocationsSortBy: {
                columns: [
                    { field: 'Name', direction: 'Ascending' },
                ]
            },
            grdLocationsClass: '',
            grdLocationsGroupOptions: {
                columns: ['GroupEmbedded.Name'],
                captionTemplate: '<span class="groupItems">${key}</span>'
            },
            selectionOptions: { type: 'Multiple' },
            showCloseIcon: true,
            locations: [],
            selectedLocations: [],
            isActive: true,
            isSuperAdmin: false,
            signedInUserIsNotSelectedUser: false,
            userOptions: [{
                label: 'Select User',
                value: ''
            }],
            users: [],
            userIdToCopy: null,
        }
    },
    methods: {
        validatePhoneNumber(number) {
            const regex = /([a-zA-Z])+/gm;
            return regex.test(number ?? this.phone);
        },
        selectUserToCopy(e) {
            this.userIdToCopy = e.value;
        },
        copyPermissions(e) {
            e.preventDefault();
            let isEdit = this.modalData.mode === 'edit';
            let userToCopy = this.users.filter(u => u.ID === this.userIdToCopy)[0];
            if (!userToCopy) {
                return;
            }
            const data = DM.copy(this.modalData.user);
            data.Groups = userToCopy.Groups;
            DM.http({
                method: !isEdit ? "POST" : "PUT",
                url: !isEdit ? '/users' : `/users/${data.ID}`,
                data
            }).then(response => {
                this.modalData.callback();
                this.modalRef.hide();
            });
        },
        saveUser() {
            let isEdit = false;
            let data = DM.copy(this.modalData.user);

            if (this.validatePhoneNumber()) {
                return DM.alertShow('Phone number is not valid', 'Save user');
            }

            if (this.modalData.mode === 'edit' || this.modalData.mode === 'profile') {
                event('edit_user_click', {
                    'edit_user_id': this.modalData.user.ID
                });

                data.ID = this.modalData.user.ID;
                isEdit = true;
            }
            else {
                event('add_user_click');
            }

            Object.assign(data, {
                Email: this.emailAddress,
                Password: this.password,
                FirstName: this.firstName,
                LastName: this.lastName,
                PhoneNumber: this.phone,
                JobTitle: this.jobTitle,
                IsSuperAdmin: this.isSuperAdmin,
                IsActive: this.isActive
            });

            // Trim whitespace from email, firstname, lastname, phonenumber and job title
            ['Email', 'FirstName', 'LastName', 'PhoneNumber', 'JobTitle'].forEach((key) => {
                data[key] = data[key].trim();
            });

            if (this.mode !== 'profile') {
                let selectedGroups = this.grdGroupItems.filter(g => g.selectedAdmin || g.selectedUserAllLocations || g.selectedUserSelectLocations);
                data.Groups = selectedGroups.map(g => {
                    return {
                        GroupID: g.ID,
                        Permission: this.getGroupPermissionString(g),
                        Locations: this.selectedLocations.filter(l => l.GroupEmbedded.ID === g.ID).map(l => {
                            return {
                                LocationID: l.ID,
                                Permission: 'FullAccess' // Need to change when image only is ready
                            }
                        })
                    }
                });
            }

            return DM.http({
                method: !isEdit ? "POST" : "PUT",
                url: !isEdit ? '/users' : `/users/${this.modalData.user.ID}`,
                data: data
            });
        },
        getGroupPermissionString(g) {
            if (g.selectedAdmin) {
                return 'Admin';
            } else if (g.selectedUserAllLocations) {
                return 'UserAllLocations';
            } else if (g.selectedUserSelectLocations) {
                return 'UserSelectLocations';
            }
        },
        btnSaveOnClick() {
            this.saveUser()
                .then(response => {
                    this.modalData.callback();
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        btnOpenChangePasswordOnClick() {
            eventBus.$emit('showAppModal', this, 'usersPasswordEditModal', {
                callback: (data) => {
                    DM.localStorageSet(app.localStorageAuthTokenName, data.AuthToken);
                    DM.localStorageSet(app.localStorageUserIDName, data.UserID);

                    this.baseStore.setAuthentication();
                    this.baseStore.user = data;
                }
            });
        },
        grdGroupRowOnClick(data) {
            if (data.column.field === 'Admin') {
                if (data.rowData.selectedAdmin) {
                    data.rowData.selectedAdmin = false;
                } else {
                    data.rowData.selectedAdmin = true;
                    data.rowData.selectedUserSelectLocations = false;
                    data.rowData.selectedUserAllLocations = false;
                }
            }

            if (data.column.field === 'UserSelectLocations') {
                if (data.rowData.selectedUserSelectLocations) {
                    data.rowData.selectedUserSelectLocations = false;
                } else {
                    data.rowData.selectedUserSelectLocations = true;
                    data.rowData.selectedAdmin = false;
                    data.rowData.selectedUserAllLocations = false;
                }
            }

            if (data.column.field === 'UserAllLocations') {
                if (data.rowData.selectedUserAllLocations) {
                    data.rowData.selectedUserAllLocations = false;
                } else {
                    data.rowData.selectedUserAllLocations = true;
                    data.rowData.selectedAdmin = false;
                    data.rowData.selectedUserSelectLocations = false;
                }
            }

            this.$refs.grdGroup?.$refs?.grdTableList?.refresh();
            this.filterLocationsByGroupID();

            setTimeout(() => {
                this.selectLocations();
            }, 100);
        },
        grdGroupActionOnClick(data) {

        },
        getGroupOptions() {
            DM.http({
                method: 'GET',
                url: '/groups'
            }).then(response => {
                response.forEach(group => {
                    group.selectedAdmin = false;
                    group.selectedUserSelectLocations = false;
                    group.selectedUserAllLocations = false;

                    if (this.modalData.mode === 'edit') {
                        let groupMatch = this.modalData.user.Groups.filter(g => g.GroupID === group.ID)[0];
                        if (groupMatch) {
                            group.selectedAdmin = groupMatch.Permission === 'Admin';
                            group.selectedUserAllLocations = groupMatch.Permission === 'UserAllLocations';
                            group.selectedUserSelectLocations = groupMatch.Permission === 'UserSelectLocations';
                        }
                    }
                });
                this.grdGroupItems = response;
                this.filterLocationsByGroupID();
            });
        },
        grdLocationsRowOnClick(data) {
            setTimeout(() => {
                this.selectedLocations = this.$refs.grdLocationsList.getSelectedRecords();
                this.selectedLocations.forEach(l => {
                    l.selectedFull = true;
                });

            }, 100);
        },
        grdLocationsActionOnClick(data) {

        },
        formatColumn(field) {
            if (field.type === 'date') {
                return { type: "date", format: "MM/dd/yyyy" };
            }

            if (field.type === 'time') {
                return { type: "dateTime", format: "hh:mm a" };
            }

            if (field.type === 'custom') {
                return field.format;
            }
        },
        selectLocations() {
            let rows = this.$refs.grdLocationsList.getRows();
            let indexes = [];
            rows.forEach(r => {
                let selectedLocation = this.selectedLocations.filter(l => l.ID === r.locationID)[0];
                if (selectedLocation) {
                    // Need to do this because SyncFusion has a bug with selecting rows when grouping is enabled
                    // This code adjusts the indexes of the location rows based on group rows
                    let offset = 0;
                    let rowToCheck = r;
                    for (let i = 0; i < document.querySelector('#userEditModalGrdLocationsList .e-gridcontent table').rows.length; i++) {
                        if (rowToCheck.previousSibling) {
                            let previousSiblingFirstChild = rowToCheck.previousSibling.firstChild;
                            if (previousSiblingFirstChild.classList.contains('e-recordplusexpand')) {
                                offset++;
                            }

                            rowToCheck = rowToCheck.previousSibling;
                        } else {
                            break;
                        }
                    }

                    indexes.push((r.rowIndex - offset));
                }
            });

            this.$refs.grdLocationsList.selectRows(indexes);

            setTimeout(() => { // For some reason it loads scrolled to the bottom, so start it at the top
                document.querySelector('#userEditModalGrdLocationsList .e-content').scrollTop = 0;
            });
        },
        rowDataBound(rowData) {
            rowData.row.locationID = rowData.data.ID;
            rowData.row.selectedFull = rowData.data.selectedFull;
        },
        getLocationsOptions() {
            DM.http({
                method: 'GET',
                url: '/locations'
            }).then(response => {
                response.forEach(location => {
                    location.selectedFull = false;
                    location.selectedImage = false;

                    if (this.modalData.mode === 'edit') {
                        let groupMatch = this.modalData.user.Groups.filter(g => g.GroupID === location.GroupEmbedded.ID)[0];
                        if (groupMatch) {
                            let locationMatch = groupMatch.Locations.filter(l => l.LocationID === location.ID)[0];
                            if (locationMatch) {
                                location.selectedFull = locationMatch.Permission === 'FullAccess';
                                location.selectedImage = locationMatch.Permission === 'Image';
                                this.selectedLocations.push(location);
                            }
                        }
                    }
                });

                this.locations = response;
                this.filterLocationsByGroupID();

                if (this.modalData.mode === 'edit') {
                    setTimeout(() => {
                        this.selectLocations();
                    }, 100);
                }
            });
        },
        filterLocationsByGroupID() {
            let selectedGroupIDs = this.grdGroupItems.filter(g => g.selectedUserSelectLocations).map(g => g.ID);
            this.grdLocationsItems = this.locations.filter(l => selectedGroupIDs.includes(l.GroupEmbedded.ID));
        },
        defineColumns() {
            let adminWidth = '100px';
            let userAllLocationsWidth = '120px';
            let userSelectLocationsWidth = '120px';
            if (window.innerWidth < 666) {
                adminWidth = '55px';
                userAllLocationsWidth = '75px';
                userSelectLocationsWidth = '85px';
            }

            this.grdGroupFields = [
                {
                    key: 'Name',
                    label: 'Group',
                    width: '200px'
                },
                {
                    key: 'Admin',
                    label: 'Admin',
                    formatter: (column, data) => {
                        column.rowData = data;
                        return data.selectedAdmin ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                    },
                    textAlign: 'Center',
                    allowSorting: false,
                    width: adminWidth
                },
                {
                    key: 'UserAllLocations',
                    label: 'User - All Locations',
                    formatter: (column, data) => {
                        column.rowData = data;
                        return data.selectedUserAllLocations ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                    },
                    textAlign: 'Center',
                    allowSorting: false,
                    width: userAllLocationsWidth
                },
                {
                    key: 'UserSelectLocations',
                    label: 'User - Select Locations',
                    formatter: (column, data) => {
                        column.rowData = data;
                        return data.selectedUserSelectLocations ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                    },
                    textAlign: 'Center',
                    allowSorting: false,
                    width: userSelectLocationsWidth
                },
            ];
        }
    },
    computed: {
        ...mapStores(useBaseStore),
        isUserSuperAdmin() {
            return this.baseStore.user?.IsSuperAdmin
        },
        userHasBeenSelectedAsAUser() {
            return this.grdGroupItems.filter(g => g.selectedUserSelectLocations).length > 0;
        }
    },
    created() {
        this.getLocationsOptions();
        if (this.isUserSuperAdmin) {
            api.getUsers().then(response => {
                this.users = response.map(u => {
                    u.FullName = u.FirstName + ' ' + u.LastName;
                    return u;
                });
                this.userOptions = this.users.map(u => {
                    return {
                        label: u.FullName + ' (' + u.Email + ')' + (u.IsActive ? '' : ' - Inactive'),
                        value: u.ID
                    }
                });
            });
        }

        api.getUsers().then(response => {
            this.users = response.map(u => {
                u.FullName = u.FirstName + ' ' + u.LastName;
                return u;
            });
            this.userOptions = this.users.map(u => {
                return {
                    label: u.FullName + ' (' + u.Email + ')' + (u.IsActive ? '' : ' - Inactive'),
                    value: u.ID
                }
            });
        });

        let user = this.modalData.user;
        this.mode = this.modalData.mode;


        if (this.baseStore.user?.ID !== user.ID) {
            this.signedInUserIsNotSelectedUser = true;
        }

        if (this.modalData.mode === 'edit' || this.modalData.mode === 'profile') {
            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit User: ' + this.modalData.user.FirstName + ' ' + this.modalData.user.LastName;
            }

            if (this.modalData.mode === 'profile') {
                this.lblTitle = 'My Profile';

                // if (!this.baseStore.user?.HasUpdatedJobTitle) {
                if (this.baseStore.user?.JobTitle === '') {
                    this.jobTitle = '';
                    this.showCloseIcon = false;

                    DM.alertShow('User profiles now require a Job Title. Please enter your Job Title to continue.', 'New Property: Job Title');
                }
                else {
                    this.jobTitle = user.JobTitle;
                    this.showCloseIcon = true;
                }
            }
            else {
                this.jobTitle = user.JobTitle;
                this.showCloseIcon = true;
            }

            this.emailAddress = user.Email;
            this.firstName = user.FirstName;
            this.lastName = user.LastName;
            this.phone = user.PhoneNumber;
            this.isSuperAdmin = user.IsSuperAdmin;
            this.isActive = user.IsActive;
        }

        this.grdLocationsFields = [
            {
                key: 'GroupEmbedded.Name',
                label: '',
            },
            {
                key: 'Name',
                label: 'Location',
            },
            {
                key: 'FullAccess',
                label: 'Full',
                type: 'checkbox',
                allowSorting: false,
                textAlign: 'Center',
                width: '80px'
            },
        ];

        if (this.baseStore.user?.IsSuperAdmin) {
            this.getGroupOptions(true);
        } else {
            this.getGroupOptions(false);
        }

        this.defineColumns();
    },
    mounted() {
        let defineColumns;
        window.onresize = () => {
            clearTimeout(defineColumns);
            defineColumns = setTimeout(this.defineColumns, 100);
        };
    }
}
</script>
